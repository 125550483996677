@import '../../../assets/global';
@import '../../../assets/variables';
@import '../../../assets/mixins';

.ProductsPage{@include sec-default();
  // &::before{@include before();@include sec-bgimage2(); background-image: url('../../../assets/images/bg22.png'); background-size: 30%; background-repeat: repeat;z-index:-4;}
  // &::after{@include before(); background-color: rgba($white,.96); z-index: -3;}
  
  p span {color: $color1-md;}
  p.directions b{font-size:1.13rem;}
  p.directions {display: none;}

  .colorPageHead{@include sec-default(); position: relative; z-index: 0;
    &::before{@include before(); background-color: $fa2; width: 55%; left: 6%; z-index: -2;}
    p{font-size:18px; 
      b { font-size:1.1em;}
    }
    p b, h2 b{color: $green;}
    h1{color:$color1; text-transform: uppercase; font-size:2.3rem;}
    h1 span{color: $green; font-size:1.08em;}
    
    .imgInline{ width:48.5%; position:relative; z-index: 2; float:right; margin-top: 14%; margin-bottom: -6%; margin-right:-5%;}
    .textBox{width:48%; float:left; padding: 95px 0 80px; background-color: $fa2;}
  }
  
  .colorPageColl{@include sec-default(); padding:0px 0 60px;
    .textBox {display: inline-block;}
    .oilBlock, .topiBlock, .skinBlock, .petBlock, .oilImg, .topiImg, .skinImg, .petImg, .imgOver {position: relative; display: inline-block; vertical-align: top; 
      img{position: relative; z-index:2;}
      img.mobile{display: none;}
      h3{font-size:2rem; font-weight: bold; font-family: $pop; text-transform: uppercase; margin:0; letter-spacing: 1px; color:$color1;}
      p{position: relative; padding-left:25px;
        &::before{@include before(); width:2px; background-color: rgba($color1,.2); left:5px;}
      }
    }
    
    .imgOver{width:50%; float:right; margin-top:30px; margin-right:3%; z-index: 2; img{border:0;}}
    .oilBlock {@include sec-default(); padding: 100px 0 0; 
      &::before{@include before(); height:105%; background-color: rgba($color1-lt3,.92); width:70%; left:30%; background-color:#f4f8f6;}
      .oilImg {width:35%; float:left;}
      .textBox {width:39%; margin-left:7%; margin-top: 3%;}
    }
    
    .topiBlock {width: 40%; padding:100px 70px 130px; margin-left: 7%;
      .textBox{width:82%; margin: 5% 0 5% 4%;}
      &::before{@include before(); background-color: $color1-md; margin-top: -15%; width:105%; height:125%;}
      p,h3{color: $white;
        span{color: #9bd9c1; font-weight:bold;}
      }
      p::before{background-color: rgba($color1-lt,.3);}
    }
    .topiImg{width:50%; float:right; margin-top:-20%; margin-right:-4%;}
    
    .skinImg{width:45%; float:left; margin-top:-4%; margin-left:-4%; }
    .skinBlock {width: 80%; float:right; padding: 0px 0 100px;
      .textBox{width:45%; margin-left: 33%; margin-bottom: 10%; margin-top:-5%;}
      &::before{@include before(); background-color: $color1-lt3; margin-top: -30%; height:170%; z-index:-2;}
    }
    
    .petImg{width:40%; margin-top:5%; margin-left: -5%;}
    .petBlock {width: 55%; padding: 80px 0 100px; margin-top: -12%; margin-left:7%;
      .textBox{width:60%;margin-left: 13%; margin-top: 8%;}
      &::before{@include before(); background-color:#fcf8f9; height:120%; background-color:$fa;}
    }    
  }
  
  
  .oilBlock, .topiBlock, .skinBlock, .petBlock, .colorPageHead{
    &::before, &::after, img{box-shadow: 0px 5px 20px rgba($black,.1);}
  }
  
  
  @include bp(su) {
    .colorPageHead{
      &::before{display:none;}
      .textBox{position: relative; z-index: 0;
        &::before{@include before(); width:150%; left: -12%; height:105%; background-color: $fa2; z-index: -2;box-shadow: 0px 5px 20px rgba($black,.1);}
      }
    }
    .colorPageColl  {max-width:1400px; @include center-block();}
  }
  
  
  @media (max-width:1200px) {
    .colorPageHead{
      &::before{ width: 60%; left: 2%;}
      .textBox{padding: 95px 0 80px 30px; }
    }
    
    .colorPageColl .oilBlock {padding: 100px 0; }
  }
  
  @include bp(tb) {padding-bottom:80px;
    .colorPageHead{
      &::before{ width: 58%; left: 5%; }
      p{font-size:17px; }
      h1{font-size:2rem;}
      
      .imgInline{ margin-top: 25%; margin-right:-3%;}
      .textBox{width:42%; margin-left: 6%;padding: 75px 0 60px; }
    }
    
    .colorPageColl{
      .oilBlock, .topiBlock, .skinBlock, .petBlock, .oilImg, .topiImg, .skinImg, .petImg, .imgOver {
        h3{font-size:1.7rem;}
        p{font-size:16px;}
      }
      
      .oilBlock {padding: 60px 0 0px; 
        .oilImg {width:40%; margin-left:-3%; margin-top:25%; margin-bottom: -5%;}
        .textBox {margin: 11% 0 8% 5%;width:47%;}
      }
      
      .topiBlock { width: 50%; padding:100px 70px 100px;
        .textBox{@include sec-default(); margin: 5% 0;}
        &::before{margin-top: -10%;}
      }
      .topiImg{margin-top:-10%;}
      
      .skinImg{ margin-top:-2%; }
      .skinBlock {
        .textBox{width:70%; margin: 10% 0 10% 15%;}
      }
      
      .petImg{width:40%; margin-top:0; margin-left: -6%;}
      .petBlock {width: 60%; padding: 80px 0 100px; margin-top: -12%; margin-left:0;
        .textBox{width:65%; margin-left: 13%; margin-top: 8%;}
        &::before{ height:120%;}
      } 
    }
  }
  
  @include bp(tn) {padding-bottom:10px;
    .colorPageHead{
      &::before{ width: 70%; left: 5%;}
      p{font-size:16px; }
      h1{font-size:1.8rem; margin-bottom: 10px;}
      
      .imgInline{width:42%; margin-top: 30%; margin-right:-1%;}
      .textBox{width:50%; margin-left: 6%;padding: 50px 0 40px;}
    }
    
    .colorPageColl{
      .oilBlock, .topiBlock, .skinBlock, .petBlock {
        h3{font-size:1.5rem;}
        p{font-size:15.5px;}
      }
      
      .oilBlock {padding: 0px; 
        &::before{ height:105%; width:95%; left:4%;}
        .oilImg {width:60%; margin-left:20%; margin-top:5%; margin-bottom: 0;}
        .textBox {margin: 15% 2% 0px 12%; width:75%;}
        
        img{display: none;}
        img.mobile{display: block;}
      }
      
      .topiBlock { width: 90%; padding:30px 0 70px; float:left; margin-left:-5%;
        &::before{margin-top: -13%;}
        .textBox{margin: 5% auto; width:75%; @include center-block();}
      }
      .topiImg{margin-top:-5%;}
      
      .skinImg{ margin-top:-5%; margin-left:0;}
      .skinBlock {width:90%;
        .textBox{width:80%; margin: 10% 0 10% 10%;}
      }
      
      .petImg{margin-top:-17%; margin-left: 0;
        position: absolute; width:35%; right:3%;
      }
      .petBlock {width: 80%; padding: 30px 0 60px; margin-top: -10%; margin-left:-5%;
        .textBox{width:75%; margin-left: 9%; margin-top: 8%;}
        &::before{ height:100%;}
      } 
    }
  }
  
  @include bp(md) {    
    .colorPageHead{
      p{font-size:.95em; }
      h1{font-size:1.6rem;}
      
      .imgInline{margin-top: 35%;}
      .textBox{padding: 40px 0 20px;}
    }
    
    .colorPageColl{padding-bottom:35px;
      .oilBlock, .topiBlock, .skinBlock, .petBlock {@include sec-default(); margin-top:0; margin-bottom:0;
        h3{font-size:1.2rem;}
      }
      
      .oilBlock {padding: 40px 0 0; 
        &::before{ height:75%; top:0; width:105%; left:-5%; margin-top:0;}
        .textBox {width:90%; margin:5% 0 0px 1%; float:left;}
      }
      
      .topiBlock { padding:10px 0 60px;
        &::before{width:100%;}
        .textBox{margin: 5% auto; width:85%;}
      }
      .topiImg, .skinImg { position: relative; width:47%; margin: -5% 1% 0; right:auto; left:auto;
        max-height:190px; overflow: hidden;
      }
      
      .skinBlock {margin-left:-5%; padding: 0 0 60px;
        &::before{ margin-top: -20%; height:140%;}
        .textBox{width:85%; margin: 7% 0 0 5%;}
      }
      
      .petImg{margin-top:-14%; position: relative; width:35%; float:right; right:0;}
      .petBlock { padding: 30px 0 60px; margin-top: 0; margin-left:0;
        .textBox{width:85%; @include center-block(); margin: 3% auto 0;}
      } 
    }
  }
  
  @include bp(pl) {
    .colorPageHead{
      &::before{ width:100%; left:0; background-color:$white; box-shadow:none;height:150%; top:-5%;}
      p{font-size:.9em; }
      h1{font-size:1.5rem;}
      
      .imgInline{display: none;}
      .textBox{width: 90%; margin:0 0 0 4.5%; padding: 0px; background-color: transparent;}
    }
    
    .colorPageColl{
      .insideAlt{width:85%;}
      .oilBlock, .topiBlock, .skinBlock, .petBlock {box-shadow: 0px 5px 20px rgba($black,.1); margin:40px 0 0; padding: 40px 0 30px; 
        &::before, &::after{display: none;}
        .textBox {width:80%;@include center-block(); margin:0 auto;}
        p::before{z-index: 1;}
      }
      .oilBlock {background-color:#f4f8f6; padding-bottom: 0;}
      
      .topiBlock{background-color: $color1-md; padding: 40px 0 60px;}
      
      .skinBlock{background-color: $color1-lt3;}
      .petBlock{background-color:#fcf8f9; padding: 40px 0 60px;}
      .petImg{margin-top:-10%;width:40%; right:4%;}
    }
  }
  
  @include bp(oc) {
    .colorPageHead{
      &::before{height:100%; top:0;}
      .textBox {padding-bottom: 0px;}
    }
    
    .colorPageColl{
      .insideAlt{width: 90%;}
      .oilBlock, .topiBlock, .skinBlock, .petBlock {padding: 30px 0 20px; 
        .textBox {width:90%;}
      }
      .oilBlock { padding-bottom: 0; margin-top:15px;}
      .topiBlock, .petBlock{padding-bottom: 45px;}
      .topiImg, .skinImg {max-height:175px;}
      .petImg{margin-top:-10%;width:40%; right:4%;}
    }
  }
}