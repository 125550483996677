@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Accordion {
  @include sec-default();
  li {@include sec-default(); margin: 0 0 15px; text-align:left;}
  li:nth-child(n+2){margin-top:inherit;}
  button {background-image:none; background:transparent; border:none; font-family: $body; font-weight: bold; font-size: 1.15rem;}

  .custContent{padding-left: 20px;}
  p{margin-top:5px;}
  
  &.openLgAcc {
    // @include bp(lg) {
    @media (min-width:1024px) {
      li{position: relative;z-index: 0;
        &::before {@include before(); z-index: 1;}
      }
      li button {padding-left:0;
        &::before{display: none;}
      }
      [hidden] {display: block !important;}
      .custContent{margin-top:0; padding-left:0;}
      // .agLink{position:relative; z-index: 5;}
    }
  }
  &.homeStyle{
    li button{margin:0 0 8px; padding-left:0; color:$color1; font-weight:bold; font-size: 1.23em; font-family: $karla;}
    .custContent {padding-left:0; margin-top:0; 
      // padding-bottom:20px;
      h3{display: none;}
    }
  }
  
  // KPKP
  &.sizeColor{
    li{margin-bottom:25px;}
    .custTitle{@include sec-default();font-family: $karla; text-align: left; color:$color1}
    .custContent{width:90%;}
    
    
    // if split view // 
    li{margin-bottom:40px;background-color: $cream; padding:30px; box-shadow: 0px 5px 20px rgba($black,.2);}
    .custContent{width:98%;}
    .custTitle {margin-bottom:15px; color:$green;}
    
    @include bp(br) {
      li{margin-bottom:0px; padding:0px; box-shadow: 0px 5px 20px rgba($black,0); background-color: transparent;}
      .custTitle {font-size:1rem; margin-bottom: 0;}
      .custContent{padding-top:15px;}
    }
   
    // @include bp(tn) {}
  }
}