@import '../../../assets/global';
@import '../../../assets/variables';
@import '../../../assets/mixins';

.FAQPage{ 
  .faqSection {@include sec-default(); padding:70px 0 85px; }
  .fullFaq {@include sec-default();}
  .smallFaq{display: none;}
  .agLink{font-size:.8rem;}
  
  // KPKP
  .hdStrip{@include sec-default(); position: relative; z-index: 9;}
  .faqSection {padding:0;}
  .fwImg{@include sec-default(); height:150px;}
  .colorIntro{@include sec-default(); padding:200px 0 160px;text-align: center; background-color: $color1-lt3; position: relative; z-index: 0;
    margin-top: -70px;
    &::after{@include after(); z-index: -3; content: "Q"; left: 0px; top:40%; font-size: 250px; font-weight:bold;}
    &::before{@include before();background-color: rgba($color1-lt3,.95); z-index: -2;}
  
    h2{font-size:2em; font-family: $karla; letter-spacing: -1px; 
      font-size: 1.1em; letter-spacing: .7px; margin-bottom: 0; color:$color1; font-weight: bold;
    }
    p{font-size: 1.3em;}
    a{font-weight: bold; font-family: $karla; font-size:1.1em;
      &:hover {color: $green;}
    }
  }
  
  .colorFaq{@include sec-default(); padding:70px 0 40px;
    position:relative; z-index:0;
    
    .questions, .qList {display: inline-block; vertical-align: top;}
    .questions {width:35%;margin-left:-2%;
      li{@include sec-default(); margin-bottom:15px; 
        a{font-family: $karla; color:$color1; font-weight: 600; letter-spacing: -.5px; font-size: 1.1em;
          position: relative; z-index: 0;
          &::before{@include after(); height: 8px; bottom:2px; left:-1px; width:100px; background-color: $color1-lt2;}
        }
      }
    }
    .qList {width:64%; float:right;
      p span{font-family: $karla; font-weight:bold; font-size:1.1em;}
      p b span{font-size: 1em;}
    }
    ul{margin-top:40px;}
    .imgAbs{ margin-top:-140px; margin-bottom:60px; @include sec-default();}
    
    
    // images and special faq lays
    .qList li{z-index: 2;
      .text, img{display: inline-block; vertical-align: top;}
      .agLink{position:relative; z-index: 5;}
    }
    .faqWhat {
      .text{float:left; width:70%;}
      img {width:28%; float: right; margin-top:10px;}
    }
    .faqProd {
      img{width:40%; width:48%;}
      .text{width:56%; width:48%; float:right; margin-top:4%;}
    }
    .faqPet {
      img{width:57%;}
      .text{width:40%; float:right; margin-top:20px;}
      img.textImg{@include sec-default(); margin-top:10px;}
    }
  }
  // KPKP
  
  @include bp(tb) {
    .colorIntro{padding:150px 0;
      &::after{top:42%; font-size: 200px; }
      h2{font-size: 1em;}
      p{font-size: 1.15em;}
    }
    
    .colorFaq{padding:50px 0 40px;
      .questions {width:35%; margin-left:0;
        li{margin-bottom:15px; 
          a{font-size: 1em;
            &::before{height: 5px; bottom:1px;width:85px;}
          }
        }
      }
      .qList {width:60%; margin-right: 2%;
        p{font-size: .9em;}
      }
      ul{margin-top:20px;}
      .imgAbs{margin-top:-110px; margin-bottom:45px;}
    }
  }
  
  @include bp(br) {
    .colorIntro{padding:150px 0 80px;
      &::after{top:48%; font-size: 185px; }
    }
    
    .colorFaq{padding:30px 0 40px;
      .questions, .imgAbs {display: none;}
      .qList { width:80%; @include center-block();}
    }
  }
  
  @include bp(tn) {
    .colorIntro{padding:120px 0 50px;
      &::after{top:51%; font-size: 170px; }
      h2{font-size: 1em;}
      p{font-size: 1.1em;}
    }
    
    .colorFaq{padding:20px 0 50px;
      .qList li{
        .text{@include sec-default();}
        img{display:none;}
      }
      .faqPet img.textImg{display: none; }
    }
  }
  
  @include bp(md) {
    .colorIntro{padding:100px 0 30px;
      &::after{top:54%; font-size: 150px; }
      p{font-size: 1em;}
    }
    
    .colorFaq{
      .qList {width:90%;}
    }
  }
  
  @include bp(oc) {
    .colorIntro{margin-top:20px;padding:40px 0;
      &::after{top:30%; font-size: 130px; }
      br{display: none;}
      h2{margin-top:0;}
      p{font-size: .93em; margin-bottom: 0;}
    }
    
    .colorFaq{
      .qList {width:96%;}
    }
  }
}