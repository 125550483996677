@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Footer{@include sec-default(); background-color:$color1; 
position: relative; z-index: 1;
  .TopFooter{@include sec-default(); padding: 20px 0;}
  li, a, p{color:$white; font-size:.94rem;}
  li{margin-bottom:10px; margin-top:0;}

  .ftCol{display: inline-block; vertical-align: top;
    // &.logoCol{width:20%; margin-left:5%; text-align:center;}
    &.logoCol{width:28%; margin-left:2%; text-align:center;}
    img{@include center-block(); width:216px;}
    &.contact1Col, &.contact2Col{width:35%; float:left;}
    &.contact2Col{float:right; text-align:right;}
  }
  
  .copyFooter{@include sec-default(); padding: 10px 0 8px; position: relative; z-index: 0;
    background-color: $color1-dk; text-align: center;
    p, a{font-size:13px; margin:0;}
    
    br{display: none;}
    .ftMenu{position: absolute; right:1.5%; z-index: 2; margin-top:-30px; 
      padding:0; border:0;
      span{color:$white; }
      svg {width:25px; height:25px;}
    }
  }
  
  .ftMenuOC{background-color: $color1-dk; border-left:1px solid $black;}
  .mobileNavBtn { padding:0; border:0; float:right; color:$white;
    span{color:$white; }
    svg {width:25px; height:25px;}
  }
  
  @include bp(tb) {
    li, a, p{font-size:15px;}
    .ftCol {
      &.logoCol{width:24%; margin-left:1.5%; }
      img{width:100%;}
      &.contact1Col, &.contact2Col{width:37%;}
    }
  }
  
  @include bp(tn) {
    .TopFooter{padding: 20px 0 5px;}
    .medDef{width:90%;}
    .ftCol {
      &.logoCol, &.contact1Col, &.contact2Col{@include sec-default(); text-align: center;}
      img{ width:216px; margin-bottom: 20px;}
    }
    
    .copyFooter svg {width:21px; height:21px;}
  }
  
  @include bp(pl) {
    .copyFooter br{display: block;}
  }
  
  @include bp(pl) {
    .copyFooter {padding-top:18px;
      .ftMenu {position: relative; right: auto; margin-top:5px;}
    }
  }
}