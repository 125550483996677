@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.HeaderAltSec{@include sec-default(); background-color:transparent; padding:20px 0 60px; position: relative; z-index: 0;
  .TopBar{@include sec-default(); margin-bottom:15px; font-size:.88rem;}
  .TopLeft, .TopRight{display: inline-block;}
  .TopRight{float:right; text-align: right;}
  
  
  .logoLinkAbs {width:25%; display:inline-block;
    img{width:220px; @include center-block();}
  }
  
  @media (min-width:1800px) {width: 1600px; @include center-block();}
  @include bp(dt) {.logoLinkAbs img{width:250px;}}
  
  @include bp(tn) {
    .logoLinkAbs {width:35%; float:left;
      img{width:210px;}
    }
  }
  
  @media (max-width:640px) {
    padding: 10px 0 20px;
    .logoLinkAbs {@include sec-default();}
  }
}

.haWrap{@include sec-default();}
.HomeHeader {background-color:$white; 
  .HeaderAltSec{
    padding-bottom:10px;
    @include bp(tb) {padding-bottom:20px;}
    @media (max-width:640px) {padding-bottom:0;} 
  }
}
