@import '../../../assets/global';
@import '../../../assets/variables';
@import '../../../assets/mixins';

.ContactPage{@include sec-default();
  position: relative; z-index: 0; 
  &::before{@include before(); width:35%; height:85%; top:17.3%; left:13%; background-color: $color1-lt3;}
  
  p {font-size:18px;
    b {font-size:18.5px;}
  }
  
  .contactHead{@include sec-default();
    .tblock{width:70%; @include center-block(); text-align: center;}
    p b, h2 b, p a{color: #9bd9c1;}
    h1{color:$text-lt; text-transform: uppercase;margin:0; line-height:1.2em;}
    h1 span{color: $green;}
    .image{margin-top:10px; }
  }
  
  .contactWrap{@include sec-default(); padding-top:50px;
    .info, .text {display: inline-block; vertical-align: top; width:45%;}
    .info{float: left; padding-left:20px; margin-top:15px;}
    .text{width:48%; padding:30px 0;}
    .inner{width:85%; float:right;}
    
    .cBlock, .cBlockL{@include sec-default(); margin-bottom: 25px;}
    p{margin: 0 0 12px;}
    p.label{font-size:.88rem; font-weight: bold; text-transform: uppercase; color:$color1; 
      letter-spacing: 1px; margin-bottom: 3px; font-family: $pop; line-height:1em;
    }
    .text {
      p b {color: $color1;}
      a {text-decoration: underline;}
    }
  }
  
  h2{color:$color1; text-transform: uppercase; font-size:2.8rem; font-weight:bold; font-family: $pop; letter-spacing: .5px; 
    text-align: center; line-height: .8em; 
    margin-top:30px; margin-bottom: 50px;
    span{color: $green; font-family: $sulph; letter-spacing: 2px; font-size:1.2em;}
  }
  
  @media screen and (min-width:1600px) {
    &::before{ width:530px; left:15%; display: none;}
    .contactHead{position: relative; z-index: 1;}
    .style1{width:1200px; @include center-block();}
    .xlOverlay{@include sec-default(); position: relative; z-index: 0;
      &::before{@include before(); height:85%; top:17.3%; width:530px; margin-left:4%; background-color: $color1-lt3;}
    }
  }
  
  @media screen and (max-width:1280px) {
    &::before{left:8%; }
  }
  
  @include bp(tb) {
    &::before{left:0; width:44%; }
    .contactHead .image {width:90%; @include center-block();}
    
    .contactWrap{margin-top:0px;
      .info{width: 42%;  margin-left:8%; padding-left:0px; margin-top:30px;}
      .text{width:42%; padding:30px 0 0;}
      .inner{@include sec-default();}
      .cBlock, .cBlockL{ margin-bottom: 15px;}
      
      h2{font-size: 2.2rem;}
      p {font-size: .9em;}
      p.label {font-size: .8em;}
      p b {font-size: 1em;}
      .agLink b{font-size: 15px; letter-spacing: 0;}
    }
  }
  
  @include bp(br) {
    &::after{@include after(); background-color: $white; right:0; bottom:0;}
    &::before, &::after{width:50%; height:84%;}
    
    .contactWrap{text-align: center;
      .info{ @include sec-default(); margin:20px 0 0;}
      .text{width:75%; @include center-block(); padding: 0 0;}
      h2{margin: 40px 0 5px; font-size:1.8rem;
        span{font-size: 1.1em;}
      }
    }
  }
  
  @include bp(tn) {
    .contactWrap { 
      .text{width:95%;}
      h2{ font-size: 1.5rem;}
    }
  }
  
  @media screen and (max-width:640px) {
    .contactHead h1{color: $color1;}
  }
}