@import '../../../assets/global';
@import '../../../assets/variables';
@import '../../../assets/mixins';

.HomePage{@include sec-default(); margin-top:-10px; overflow-x: hidden;
  .curveGreen, .curve2 {@include sec-default(); padding:150px 0 0; background-color: $color1-md; text-align: center;
    // @include sec-bgimage2(); background-image:url('../../../assets/images/plants1.jpg');
    position: relative; z-index: 0;
    
    &::before{@include before(); background-color: rgba($color1-dk, .96);}
    &::after{@include after();
      width: 120%; height:50%; left:-10%; background-color: $white; border-radius: 45%; bottom:-20%;
    }
    
    h1, h2, h3, p {color: $white; @include tshadowl(rgba($black,.6));}
    h1{text-transform: uppercase; letter-spacing: 1px; font-size:2.5em;
      span{color:$green;}
      br{display: none;}
      // color:$color1; font-size: 5em; letter-spacing: 3px; opacity: .3; text-align:center; position: absolute; top:10%;
    }
    h2{width:80%; @include center-block(); font-weight:$semi;font-family: $karla;
      span{color:lighten($green, 10%); font-size:1em; }
    }
    img{width:60%; @include center-block(); position: relative; z-index: 5; margin-top:120px;margin-top:85px;}
    
    
    // &::before{@include before(); background-color: rgba($color1-dk, .92);}
  }
  
  .curve2 {padding: 50px;}
  
  .curveRev {@include sec-default(); 
    position: relative; z-index: 3; height:120px;
    &::before{@include before();width: 115%; height:90%; left:-7.5%;background-color: $white; border-radius: 50%; top:30%;}
    margin-bottom: -20px;
  }
 
  
  .headline{@include sec-default(); margin-bottom: 0; padding:40px 0 0;
    position: relative; z-index: 0;
    &::before{@include after(); width: 12%; height: 2px; background-color: $color1-dk; bottom:43%;}
    .text{width:820px; @include center-block(); padding:40px 0 0 0px;}
    
    .dets, h2 {display: inline-block; width:40%;}
    .dets {width:58%;float:right;}
    h2{color:$color1; font-weight:bold; text-transform: capitalize; font-family: $karla;
      font-size: 1.9rem; text-transform: uppercase;
      b{font-size: 1em; color: $color2; color:$green;}
    }
  }
  
  .bgBlock, .bgBlock2{@include sec-default(); min-height:200px;
    position:relative; z-index: 0; text-align: center; padding: 100px 0 70px;
    &::before{@include before();background-color: rgba($color1-dk,.8); z-index: -2;}
    
    h3{font-size:1.65rem; font-weight: bold; font-family: $pop; text-transform: uppercase; margin:30px 0; letter-spacing: 1px; 
      color:$white; text-shadow:0px 1px 2px rgba($black,.7); 
    }
    p{font-size:1rem; width:60%; @include center-block(); margin-top: 15px;
      font-weight:500;color: #9bd9c1; text-shadow:0px 1px 2px rgba($black,.7); 
    }
    
    &::after{@include after();
      width: 120%; height:60%; 
      left:-10%;
      background-color: $white; border-radius: 45%;
      bottom:-20%;
    }
  }
  
  
  .lineRow3{@include sec-default(); position: relative;
    // @include sec-bgimage2(); background-image: url('../../../assets/images/single.png');background-position: center center; background-size: 7%;
    padding:20px 0 120px; margin: -40px 0 0px;
    
    h3, .Link, .LinkB{margin:0 0 15px; color:$color1; font-weight:bold; font-size: 1.23em; font-family: $karla;}
  
    .inLine1,.inLine2,.inLine3{display: inline-block; width:39%; vertical-align: top; float: none; 
    // margin-left:3%;
      p{margin-top:35px;}
    }
    .inLine2{width:15%; margin-left:4%;
      img{width:104px; @include center-block();}
    }
    .inLine3{width:40%; float:right; margin-left: 0; margin-right: -2%;;
      .LinkB{font-size:1rem;letter-spacing:0; font-weight: bold;}
    }
    .faqLink{text-align:center; position: absolute; z-index: 2; bottom:20px;margin-left:20%; 
      &::after{@include after(); height:7px; background-color: rgba($green,.2); left:0; bottom:3px;}
    }

    
    // // with all 3 columns and green ::before // 
    z-index: 0; padding-bottom: 40px;
    &::before{@include after(); background-color: $color1-lt3; height: 50%; bottom:0;}
    .faqLinkRow{position: relative; z-index: 0;
      &::after{@include after(); height:7px; background-color: rgba($green,.2); left:0; bottom:3px;}
    }
    .makeupImg{display: none;}
    h3{margin:30px 0 0; color: $color1; text-transform: uppercase;
      position: relative; z-index: 0; &::after{@include after(); width:85%; height:7px; background-color: rgba($green,.1); left:0; bottom:3px;}
      b {color: $green;}
    }
    .inLine3 p {margin-top: 25px;}
    .inLine3 p.first {margin-top: 10px;}
  }
  
  .layoutRowB{@include sec-default(); padding:130px 0;
    position: relative; z-index: 0;
    &::before, &::after {@include before(); width:15px; background-color: rgba($green, 1);}
    &::after{height:30%; background-color: $color1-dk; top:60%;}
    .medDef {width:95%;float:left;}
    .mobImg{display: none;}
    
    .left, .right, .image, .text {display: inline-block; width:50%; }
    .right{float: right; width: 47%; }
    .textBox{width:60%; @include center-block(); margin-top: 55px;}
    
    h3{text-transform: uppercase; font-weight: bold; font-family: $pop; color: $color1; font-size:2.7em; letter-spacing: 1px; line-height: 1.2em; margin:15% 0 40px; 
      b{font-family: $sulph; color:$green; letter-spacing:.5px;}
    }
    .clearBorderWhite,.clearBorder{height:30px; &::before{left:13%;}}    
  }
  
  .bottomRow{@include sec-default(); margin-top:60px;
    .image{width:65%; float:left;}
    .text{width: 25%; margin-left:5%; margin-top:10%;}
    p {font-weight: bold; font-family: $karla; font-size: 1.15em;}
    
    
    // alt
    margin-top: 0px; background-color: $color1-lt3; padding: 20px 0; text-align: center;
    .image{display: none;}
    .text {@include sec-default(); margin:0;}
  }
  
  @media (min-width: 1800px) {
    .layoutRowB{
      .medDef {width:95%;@include center-block();}
      
    }
  }
  
  
  @include bp(dt) {
    .headline{ margin-bottom: 0; padding:100px 0 0;
      &::before{width: 15%; bottom:33%;}
    }
    
    .curveRev {height: 200px;}
  }
  
  @include bp(tb) {
    .curveGreen, .curve2 {padding:150px 0 0;
      &::after{width: 120%; height:50%; left:-10%; border-radius: 45%; bottom:-20%;}
      
      h1{font-size:2.2em;}
      h2{width:70%; font-size:1.3rem;}
      img{width:45%;margin-top:75px;}
    }
    
    .headline{ padding:20px 0 0;
      &::before{width: 7%; bottom:46%;}
      
      .text{width:820px; padding:40px 0 0 0px;}
      .dets {width:58%;}
      h2{width:40%; font-size: 1.7rem; }
    }
    
    .curveRev {height: 100px;}
    
    .bgBlock{min-height:200px; padding: 50px 0 25px;
      h3{font-size:1.4rem; margin:30px 0; }
      &::after{width: 120%; height:60%; left:-10%;bottom:-20%;}
    }
    
    .lineRow3{padding:0px 0 15px; margin: -20px 0 0px;
      .med3Def{width:90%;}
      h3, .Link, .LinkB{margin:0 0 15px; font-size: 1.15em; }
      .inLine1{width:39%; 
        p.smallMarg {margin-top: 0px;}
      }
      .inLine2{width:15%; margin-left:3%;
        img{width:100px;}
      }
      .inLine3{width:40%; margin-left: 0; margin-right: 0;
        .LinkB{font-size:1rem;}
      }
    }
    
    .layoutRowB{ padding:70px 0;
      .medDef {width:95%;}
      .left, .right, .image, .text { width:50%; }
      .right{width: 45%; margin-right:1%;}
      .textBox{width:75%;margin-top: 40px;}
      
      h3{font-size:2.5em; margin:15% 0 25px; }
      .clearBorderWhite,.clearBorder{height:30px; 
        &::before{left:13%;}
      }
    }
  }
  
  @include bp(br) {
    .headline{ 
      &::before{width: 7%; bottom:55%;}
      .text{width:90%; padding:40px 0 0 0px;}
      .dets {width:60%;}
      h2{width:37%; font-size: 1.5rem; }
    }
  }
  
  @include bp(tn) {
    .curveGreen, .curve2 {padding:60px 0 0;
      h1{font-size:2em;}
      h2{width:80%; font-size:1.1rem;}
      img{width:45%;margin-top:50px;}
    }
    
    .headline{ padding:0px 0 0; text-align: center;
      &::before{display:none;}
      .dets {@include sec-default();}
      .text{@include sec-default(); padding-top:20px;}
      h2{margin-top:0; width:60%; @include center-block();font-size: 1.3rem; 
        br{display: none;}
      }
    }
    .curveRev {height: 55px;}
    
    .bgBlock{min-height:180px; padding: 35px 0 0px;
      h3{font-size:1.2rem; margin:30px 0; }
      &::after{width: 120%; height:60%; left:-10%;bottom:-20%;}
    }
    
    .lineRow3{padding:0px 0px 20px; margin: 0px 0px;
      &::before{height:60px; height:47%;}
      .med3Def{width:75%;}
      h3, .Link, .LinkB{font-size: 1em; }
      .faqLinkRow::after{width:70%; height:5px;}
      p{font-size: .9em;}
      
      .inLine1{width:73%;
        p{margin:10px 0 15px;}
        p.smallMarg {margin-top: 5px;}
      }
      .inLine2{width:13%; margin-left:6%; margin-top:-15px;
        img{width:100%;}
      }
      .inLine3{ width:95%; margin-top:30px; float:left;
        p{margin:10px 0;}
      }
      
      h3{margin-bottom: 0px; font-size:1.2em;
        &::after{display: none;}
      }
    }
    
    .layoutRowB{ padding:40px 0;text-align: center;
      .medDef {width:85%; @include center-block();}
      .left, .right, .image, .text { @include sec-default(); }
      .right{ 
        h3{font-size:2em; margin:0; br{display: none;} }
        .textBoxh3{margin-top: 0px; @include sec-default();}
        img{display: none;}
      }
      
      .left {
        .textBox{@include center-block(); width:85%; margin-top:10px;}
        img{display: none;}
        .mobImg {@include sec-default();margin-top:40px;}
        .mobImg img{width:80%; @include center-block();}
      }
      .clearBorderWhite,.clearBorder{height:30px; 
        &::before{left:49.5%;}
      }
    }    
  }
  
  @include bp(pl) {
    .curveGreen, .curve2 {
      h1{font-size:1.75em;}
      h2{width:85%; font-size:1.1rem;}
    }
    
    .headline{
      .insideAlt {width:90%;}
    }
    
    .lineRow3{ margin: -20px 0px 0; padding:0 0 10px;
      .inLine1{width:80%;
        p.smallMarg {margin-top: 0px;}
      }
      h3{font-size:1em;}
    }
    
    .layoutRowB{
      .left .textBox{width:90%;}
    }
  }
  
  @include bp(oc) {
    .curveGreen, .curve2 {padding:45px 0 0;
      h1{font-size:1.65em;}
      h2{font-size:.95rem; line-height: 1.5em;}
      img{width:45%;margin-top:35px;}
    }
    
    .headline{
      p{font-size:.95rem;}
    }
   
    .curveRev {height: 45px;}
    
    .lineRow3{
      &::before{ height:48%;}
      .med3Def{width:85%;}
      .inLine2{margin-left:0; float:right; margin: 10px 0 20px;}
      .inLine3{ width:95%; margin-top:30px; float:left;
        p{margin:10px 0;}
      }
    }
    
    .layoutRowB{ padding:35px 0;
      .medDef {width:100%;}
      .right h3{font-size:1.7em; }
      p{font-size:.95rem;}
    } 
  }
  
  @media (max-width:640px) {
    margin-top:0;
  }
  
  @include bp(ph) {
    .curveGreen, .curve2 {padding:30px 0 0;
      h1{font-size:1.5em; margin-bottom: 10px;}
      h2{width: 95%; margin: 5px auto;font-weight: bold;}
      img{width:50%;margin-top:35px;}
    }
    
    .headline {
      h2{width: 75%; font-size:1.1rem; margin-bottom: 10px; }
    }
    
    .layoutRowB{
      .right h3{font-size:1.2em; }
    } 
  }
  
  @include bp(sm) {
    .curveGreen, .curve2 {
      h1{font-size:1.35em; line-height: 1.1em;
        br{display: block;}
        span{font-family: $pop; font-weight: bold;}
      }
      h2{width: 100%;}
      img{width:60%;}
    }
    
    .headline {
      h2{width: 95%; }
    }
    
    .lineRow3{margin-top:-35px;
      &::before{ height:51%;}
      .inLine1{width:88%;}
      .inLine2{width:10%;}
    }
    
     
  }
}