$cream: #fbf9f8;
$f9: #f9f9f9;
$f8: #f8f8f6;
$fa: #fafaf8;
$fa2: darken($fa, 1%);
$f0: #f0f0f0;
$f3: #f3f3f3;
$f2: #f2f2f2;
$df: #dfdfdf;
$white: #fff;
$black: #000;

$color1: #035736;
$color1-lt:#ccddd6;
$color1-lt2:#e5eeea;
$color1-lt3:#f4f8f6;
$color1-dk:#012b1b;
// $color1-md: #023c25;
$color1-md: #013420;
$green: lighten($color1, 15%);
$color2: #A90E2D;
$color2-lt: #f6e6ea;
$color2-dk:#430512;
$color2-dk2:#32040d;

$appBg: $color1-md;
$text-lt: $color1-lt2;
$border: 1px solid rgba($black,.1);


$quick: 'Quicksand', sans-serif; // too rounded when bold
$pop: 'Poppins', sans-serif; // only caps
$sulph: 'Sulphur Point', sans-serif; // headings; only caps next to bolder font; spaced .5px
$karla: 'Karla', sans-serif;

$body: $quick;
$semi: 500;
$light: 300;