@import '../../../assets/global';
@import '../../../assets/variables';
@import '../../../assets/mixins';

.AboutPage{@include sec-default();
  .agLink.center {font-size:.92rem;}
  .agLink{font-weight: bold;}
  .mobileHeadImg{display: none;}
  
  .aboutUs{@include sec-default(); padding: 65px 0 60px; position: relative; overflow-x:hidden; margin-top:10px;
    &::before{@include before(); background-color: $fa; width:81%; left:19%; z-index: -3; border-left: 4px solid $color1-lt2;}
    .insideNrw {width:70%; float:right; margin-right: 7%;}
    
    h4, .tbox{display: inline-block;}
    .tbox{width:66%; margin-left:1%;}
    h4{width: 30%; float:left; font-size: 1.7rem; margin: 7% 0 0;
      color: $color1; font-weight: bold; font-family: $karla; text-transform: uppercase; 
      b{color: $green; font-size:1em}
    }
    p b{color:$color1;} 
    .clearfix.btn{margin-top:10px;}
    .agLink{background-color:darken($green, 10%);}
  }
  
  .BiosAlt {@include sec-default(); padding:45px 0; position: relative; z-index: 0;
    .bioAccTitle {font-family: $karla; padding:0; color: $green; font-weight: bold; font-size: 1rem; display: none;
      span{color: inherit; font-family: inherit; font-size: inherit;}
    }
    .bioAccContent{margin-top:0;}
    
    .BioRow {@include sec-default(); margin:50px 0;
      .image, .text {display: inline-block; width:45%; position: relative; z-index: 0; vertical-align: top;}
      .image::before {@include before(); width: 130px; height:90%; left:-10%; top:20%; background-color: $color1-lt;}
      .text{width:50%; z-index:1; background-color: $white; margin-top:5%; margin-left:-6%;}
      .textBox{@include sec-default(); padding: 40px; background-color:$white;}
      .imgMob{display: none;}
      
      h3{font-size:1.8rem; color:$green; font-weight: bold; text-transform: uppercase;font-family: $pop;
        span{font-family: $sulph; color: $color1; font-size: 1.07em; letter-spacing: .5px;}
      }
      p{font-size:16px;}
    }
    
    .BioRow.btm {padding-top:40px; 
      .image{
        &::before {left:88%; top:-10%; background-color:lighten($color1, 5%);}
      }
      
      .text{float:left; margin-left:0;}
    }
  }
  
  
  .photoRow {@include sec-default(); margin:120px 0 20px; text-align: center;
    padding-bottom:60px; position: relative; z-index: 0; margin-bottom: 0;
    &::before{@include after(); background-color: $fa; height:40%; left:0; }
    
    .collRow, .collRow2, .collRow3 {display: inline-block; width:36.5%; vertical-align: top;}
    .collRow2{width:26%; margin:0 .5%;}
    .prod1, .prod2 {width:57.9%; float:right; margin: 7px 0 0;}
    .prod2{float:left; margin: 0 0 7px;}
  }
  
  .titleRow{@include sec-default(); background-color: $fa; text-align: center; padding:15px 0 10px;
    h3{font-size:35px; font-family: $pop; color: $color1; font-weight: bold; text-transform: uppercase; margin:0;
      span{font-family: $sulph; color: $green; font-size: 37px; letter-spacing: .5px;}
    }
  }
  

  @media (max-width:1250px){
    .BiosAlt .BioRow{ margin-top:30px;
      &.btm {padding-top:0;}
    }
  }
  
  @media (min-width:1026px) {
    .bioAcc li{position: relative;z-index: 0;
      &::before {@include before(); z-index: 1;}
    }
    [hidden] {display: block !important;}
  }
  
  @include bp(tb) {
    .aboutGreen .agLink{width:45%; font-size:15px;}
    
    .aboutUs{ padding: 65px 0 60px; margin-top:60px;
      &::before{ width:93%; left:7%;}
      .insideNrw {width:85%; margin-right: 5%;}
      
      .tbox{width:71%; margin-left:0; float:right;}
      h4{width: 28%;}
      p{font-size:.95em;}
    }
    
    .photoRow {margin:75px 0 0px;}
    .titleRow {padding-top:5px;
      h3{font-size:29px;
        span{font-size: 31px;}
      }
    }
    
    .BiosAlt { padding:40px 0 0;
      .bioAccTitle{display: block;
        &::before{display: none;}
        
        // span.less{display: none;}
      }
        
      .BioRow { margin:20px 0 40px;
        .text{width:55%; width:45%; text-align: justify;}
        .textBox{ padding:25px 40px; }
        
        h3{font-size:1.6rem; margin-bottom: 10px;}
      }
      
      .BioRow.btm {margin-bottom: 45px;
        .image{
          &::before {display:none;}
          &::after {@include after(); width:130px; right:-15%; top:-10%; background-color:lighten($color1, 5%);}
        }
      }
    }
  }
  
  @include bp(br) {
    .aboutUs h4 {font-size:1.6rem;}
    
    .titleRow h3{font-size:25px;
      span{font-size: 27px;}
    }
    
    .BiosAlt { padding:25px 0 0;
      .BioRow { margin:20px 0 40px;
        .image{width:40%;}
        .text{width:60%;}
        .textBox{ padding:25px 0 20px 25px; }
        
        h3{font-size:1.3rem; margin-bottom: 10px;}
      }
      
      .BioRow.btm {
        .textBox{ padding:25px 25px 0 0; }
      }
      
      .bioAccTitle {font-size: .95em;}
      p{font-size: .9em;}
    }
  }
  
  @include bp(nn) {
    .aboutUs{ padding: 40px 0 ;
      .insideNrw {width:80%; margin-right: 7%;}
      
      .tbox, h4{@include sec-default(); }
      h4{margin-top:0; font-size: 1.3rem; text-align: center; 
        br{display: none;}
      }
      
      .agLink{@include center-block(); width:150px;}
    }
    
    .photoRow {margin:60px 0 0px;}
  }
  
  @include bp(tn) {
    .aboutGreen {  
      .agLink{width:50%; font-size:14px;}
      .clearBorderWhite {height:30px;}
    }
    
    .aboutUs {
      h4{font-size:1.2rem;}
      p{font-size:.9em;}
    }
    
    .titleRow h3{font-size:22px;
      span{font-size: 24px;}
    }
    
    .BiosAlt {
      .BioRow {
        h3{font-size:1.2rem; margin-bottom: 5px;}
      }
    }
  }
  
  @include bp(pl) {
    .aboutUs {margin-top:30px;}
  }
  
  @media (max-width:640px) {
    .aboutGreen {
      .agLink{width:180px;}
      .clearBorderWhite{margin-bottom: 5px;
        &::before {background-color: rgba($black,.2); height:20px;}
      }
    }
  }
  
  @include bp(oc) {
    .mobileHeadImg { @include sec-default();}

    .photoRow {margin:40px 0 0px; padding-bottom:20px; text-align: center;
      .collRow, .collRow3 {width:51.5%; margin:3px 0; @include sec-default();}
      .collRow2{display: none;}
      
      .waiting1, .waiting2, .prod1, .prod2 {display: inline-block; width:48%; margin: 0 0 0 1%;}
      .waiting1, .waiting2, .prod1, .prod2 {float:none;}
    }
    
    .titleRow h3{font-size:21px;
      span{font-size: 22px;}
    }
    
    .BiosAlt {padding-top:35px;
      .BioRow {
        h3{font-size:1.1rem; }
        .image::before {top:-10%; left:-7%;}
      }
      .BioRow, .BioRow.btm {margin-bottom:45px;
        .image, .text{width:90%; @include center-block();
        }
        .text{text-align: left;}
        .imgFull{display: none;}
        .imgMob{display: block;}
        .textBox{padding:0;}
      }
      .BioRow.btm .image::after{right:-7%;}
    }
  }
  
  @include bp(sm) {
    .BiosAlt {
      .BioRow, .BioRow.btm {
        .text{@include sec-default();}
      }
    }
  }
}