@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.PageHeaderGreen {@include sec-default(); padding:10px 0 80px; position: relative; z-index: 0; 
  .text, .image{display: inline-block;}
  .image{width:48%; float: left; margin-left:3%; position: relative; z-index: 0;}
  .text {width:41%;float:right; margin-top:6%;} 
  
  h2{font-weight: $light; font-size: 1.24em; margin:0 0 10px; line-height: 1.5em; color:$white;}
  p{font-size:18px; 
    color: $text-lt; 
    // color:lighten($green, 5%); font-weight: 500;
    b { font-size:18.5px;}
  }
  p b, h2 b{color: #9bd9c1;}
  h1{color:$text-lt; text-transform: uppercase;}
  h1 span{color: $green;}
  
  
  &.imageBg{
    h1{position: absolute; @include rotate(270deg); left:0; font-size:2.1rem;margin-bottom:-7%; bottom:0; margin-left: -22%; color:$black;}
    .image:before{@include before(); background-color: $color1-lt2;
      margin-left:-15%; top:27%; height:85%;
    }
    .text {margin-top:8%;} 
  }
  
  &.imageXl{padding:0;
    .image{width:95%; @include center-block();}
    img{@include sec-default();}
    .text{display: none;}
    // h1{position: absolute; @include rotate(270deg); left:0; font-size:2.2rem;margin-bottom:-30%; bottom:0; margin-left: -8%; color:$black;}
    h1{
      position: absolute; margin-top:5%; left:30px;
      font-size:2.5rem; color:$white; text-shadow: 0px 2px 2px rgba($black,.4); 
    }
  }
  
  &.productPage{
    .image{width:52%; margin-left:0;}
    .text{width:45%;}
  }
  
  &.contactPage{
    .text{float:left;}
    .image{width:55%; margin-left:0; float:right;}
    
    h1,h2{margin:0; line-height:1.2em;}
    h1{margin-bottom:50px;}// font-size:1.65em;
    h2{ letter-spacing: 2px; font-weight:bold; color: $green; text-transform: uppercase; font-family: $pop;} // font-size:1.03em;
    
    p{color: $black; font-size: 1em; margin-bottom: 30px;}
    h4{font-size:.88rem; font-weight: bold; text-transform: uppercase; color:$color1; 
        letter-spacing: 1px; margin-bottom: 0px; font-family: $pop; line-height:1em;}
  }
  
  &.imageMd{padding:0;
    .text{display: none;}
    .image{@include sec-default();}
    img, h1{display: inline-block; margin:0;}
    
    img{float:left; 
      width:55%; margin-left:-2%;
    }
    h1{line-height: 1.5em; text-transform: none; color:$white; text-shadow: 0px 2px 2px rgba($black,.4); 
      width:40%; float:right; margin-right:0;
      margin-top: 12%; font-size:2.3rem; 
      span{font-family: $karla; font-weight: bold; letter-spacing: -.5px; font-size:1.15em;}
    }
  }
  
  
  @include bp(tb) {
    // defaults
    // padding:10px 0 80px;
    // .image{width:48%; float: left; margin-left:3%; }
    // .text {width:41%;float:right; margin-top:6%;} 
    // h2{font-size: 1.24em; margin:0 0 10px;}
    // p{font-size:18px; 
    //   b { font-size:18.5px;}
    // }
    
    &.productPage{
      .image{width:52%;}
      .text{width:45%; margin-top:4%;}
      h2{font-size: 1.15em;}
    }
    
    &.imageMd{
      h1{width:40%; font-size:1.85rem; margin-right:2%;}
    }
  }
  
  @include bp(tn) {
    &.productPage{padding-bottom: 40px;
      // move padding and fonts as a universal line if others use too
      .image{width:42%; margin-left: -2%;}
      .text{width:57%; margin-top:2%;}
      h1{ font-size: 1.6em;margin-bottom: 10px; }
      h2{font-size: 1em;}
    }
    
    &.imageMd{
      h1{width:40%; font-size:1.65rem; margin-right:0; float:none; margin-left: 3%;}
    }
  }
  
  @include bp(md) {
    &.productPage{
      .text{margin-top:0; margin-right: -1%;}
      h1{ font-size: 1.4em; margin-bottom: 5px; }
      h2{font-size: .95em;}
    }
    
    &.imageMd{
      h1{font-size:1.5rem;}
    }
  }
  
  @media (max-width:640px) { padding-top: 20px;
    p, h2, h1, &.imageMd h1 {color: $color1;
      b, span {color: $green;}
    }
    &.imageMd h1{text-shadow:none; font-size:1.35rem;}
  }
  
  @include bp(oc) {
    &.productPage{padding:10px 0 30px;
      .image{display: none;}
      .text{@include sec-default(); margin:0; text-align: center;}
      h1{ font-size: 1.3em;}
      h2{font-size: .9em;}
    }
    
    &.imageMd{
      .image{margin:0;}
      img{display: none;}
      h1{@include sec-default(); text-align: center; margin: 0; font-size: 1.25rem;
        br{display: none;}
      }
    }
  }
}
