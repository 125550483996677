@import "./assets/global";
@import "./assets/variables";
@import "./assets/mixins";

.ToTopBtn{background-color: $white; margin-right:-15px; margin-bottom: 20px;}
main {@include sec-default();padding:10px 0 0px;}
@include bp(tb) {
  main {padding:0px;}
}


.AllGreenWrap {@include sec-default();
  // if Green overlay
  position: relative; z-index: 0;
  // &::before{@include before(); width:75%; left:25%; background-color: $appBg; right:0; height:650px;}
  &::before{@include after(); width:75%; top:0; right:0; background-color: $appBg; height:650px;}
  // if Green overlay
  
  @media (min-width:1800px) {&::before{ width:70%; }}
  @media (min-width:2000px) {&::before{ width:65%; }}
  @include bp(br) {&::before{ width:70%; }}
  @include bp(tn) {&::before{ width:65%;  height: 500px;}}
  @include bp(md) {&::before{ height: 450px;}}
  @media (max-width:640px) {&::before{display:none;}}
}