@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.NavItems{
  &.footerNav{
    li{@include sec-default();}
    .logoLink, .navBorder, .navPhone, .navAddress, .openHours, .altSpace{display: none;}
  }
  
  &.headerAltNav, &.homeHeaderAltNav {width:74.5%; float: right; text-align: center; margin-top:15px;
    li{display: inline-block; margin:0 12px; vertical-align: middle;}
    li,a{font-weight: $semi; color:$text-lt; text-shadow: 0px 1px 1px rgba($black,.4);position: relative; z-index: 0;
      font-size:.97rem;
    }
    li.logoLink, li.navAddress, li.navPhone, li.openHours, .mobileNav {display: none;}
    li.openHoursFloat, li.navPhoneFloat{color:$color1-lt2}
    
    .fwNav, .altSpace{display: inline-block;}    
    span.navBorder {border-right: 1px solid $text-lt; display: none;}
    a.activePath::after{@include after(); left:0; height:2px; bottom:-3px; background-color: rgba($text-lt,.4);}
    
    .mobileNavBtn {padding:0; border:0; float:right; color:$white;
      span{color:$white; }
      svg {width:25px; height:25px;}
    }
  }
  
  
  &.homeHeaderAltNav {
    li, a, li.openHoursFloat, li.navPhoneFloat {color: $color1; text-shadow: 0px 1px 1px rgba($black, 0);}
    .mobileNavBtn {color:$color1;}
  }
  
  
  
  @include bp(su) {
    &.headerAltNav, &.homeHeaderAltNav {
      li, a {font-size: 1.1rem;}
    }
  }
  
  @include bp(xl) {
    &.headerAltNav, &.homeHeaderAltNav {
      .altSpace{margin-right:7%; float:right;}
    }
  }
  
  @include bp(br) {
    &.headerAltNav, &.homeHeaderAltNav{width:60%; display:inline-block; float:none;
      .fwNav{display: none;}
      .altSpace{margin-right:0; @include sec-default();}
      .mobileNav {display: inline-block; margin-top:-5px; }
      .mobileNav{float:right;}
      
      .altSpace .navAddress {display: inline-block;}
      li, a {font-size:.88rem;}
    } 
  }
  
  @include bp(tn) {
    &.headerAltNav, &.homeHeaderAltNav{width:64%;}
  }
  
  // @include bp(oc) {
  @media (max-width:640px) {
    &.headerAltNav, &.homeHeaderAltNav{@include sec-default(); background-color: $appBg; margin-top:10px; padding-top:5px;
      .altSpace{@include center-block(); width:90%;}
    }
    
    &.homeHeaderAltNav {
      li, a, li.openHoursFloat, li.navPhoneFloat {color: $white; text-shadow: 0px 1px 1px rgba($black,.4);}
      .mobileNavBtn {color:$white;}
    }
  }
  
  @media (max-width:500px) {
    &.headerAltNav, &.homeHeaderAltNav{
      .altSpace .navAddress {display: none;}
    }
  }
}