@import '../../assets/global.scss';
@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.Social{@include sec-default();
  li {display: inline-block; margin:5px 7px;
    &:first-child{margin-left:0;}
  }
  svg {width:20px; height:20px;}

  &.light {
    a, span {color: $white;}
  }

  &.green {
    a, span {color: $color1;}
  }
  
  @include bp(tb) {
    svg {width:18px; height:18px;}
  }
}